import React from "react";
import "./FeatureBox.scss";

function FeatureBox({ image, title, text }) {
  return (
    <div className="FeatureBox">
      <div className="FeatureBox__img">
        <img src={image} alt="img" />
      </div>
      <div className="FeatureBox__text">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default FeatureBox;
