import React, { useState, useEffect } from 'react';
import { useContentCreator } from '../context/ContentCreatorContext';
import { Modal } from 'react-bootstrap';
import contentCreatorImg from '../images/contentCreator.png';
import formSuccessImg from '../images/formSuccess.png';
import './ContentCreatorModal.scss';
import ContentCreatorForm from './ContentCreatorForm';
import CloseImg from '../images/close.png';

function ContentCreatorModal() {
	const [formEdit, setFormEdit] = useState(true);
	const { showContentCreator, setShowContentCreator } = useContentCreator();

	const handleClose = () => {
		setShowContentCreator(false);
	};
	useEffect(() => {
		if (!showContentCreator) {
			setFormEdit(true);
		}
	}, [showContentCreator]);

	return (
		<Modal
			show={showContentCreator}
			onHide={handleClose}
			dialogClassName="modal-90w"
		>
			<img
				className="CloseModal"
				src={CloseImg}
				alt="close"
				onClick={handleClose}
			/>
			<div className="ContentCreatorModal">
				{formEdit ? (
					<>
						<div className="Modal__img">
							<img
								src={contentCreatorImg}
								alt="content creator"
							/>
						</div>
						<ContentCreatorForm setFormEdit={setFormEdit} />
					</>
				) : (
					<div className="Modal__success">
						<img src={formSuccessImg} alt="form success" />
						<h2>Thank you</h2>
						<p>For your interest in the meetfood community!</p>
						<p>
							We will review your profile and follow up with you
							shortly.{' '}
						</p>
						<p className="boldText">
							Hope you enjoy your food today.
						</p>
					</div>
				)}
			</div>
		</Modal>
	);
}

export default ContentCreatorModal;
