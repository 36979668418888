import Header from './components/Header';
import Feature from './components/Feature';
import About from './components/About';
import Contact from './components/Contact';
import Partners from './components/Partners';
import ContentCreatorModal from './components/ContentCreatorModal';
import BusinessPartnerModal from './components/BusinessPartnerModal';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
	return (
		<div className="App">
			<Header />
			<Feature />
			<About />
			<Partners />
			<Contact />
			<ContentCreatorModal />
			<BusinessPartnerModal />
		</div>
	);
}

export default App;
