import React from 'react';
import './Feature.scss';
import FeatureBox from './FeatureBox';
import featureimage from '../images/feature_1.png';
import featureimage1 from '../images/feature_2.png';
import featureimage2 from '../images/feature_3.png';

function Feature() {
	return (
		<section>
			<div className="Features">
				<div className="FeaturesContainer">
					<FeatureBox
						image={featureimage}
						title="Tasteful videos"
						text="Gain authentic Information from short and entertaining videos"
					/>
					<FeatureBox
						image={featureimage1}
						title="Convenient purchase"
						text="Experience seamless purchasing while watching"
					/>
					<FeatureBox
						image={featureimage2}
						title="Food community"
						text="Explore and share new food inspiration with friends and family"
					/>
				</div>
			</div>
		</section>
	);
}

export default Feature;
