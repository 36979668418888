import React, { useState, useEffect } from 'react';
import { useContentCreator } from '../context/ContentCreatorContext';
import PhoneInput from './FormComponents/PhoneInput';
import './ContentCreatorModal.scss';
import CloseImg from '../images/close.png';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isPossibleEmail } from './FormComponents/Validation';

const foodTypes = [
	'American',
	'Asian',
	'European',
	'Mediterranean',
	'Mexican',
	'Others (please sepcify)',
];

function ContentCreatorForm({ setFormEdit }) {
	const { showContentCreator } = useContentCreator();
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
		city: '',
		state: '',
		social_media_list: [],
		food_preference_list: [false, false, false, false, false, false],
	});
	const [socialMediaInput, setSocialMediaInput] = useState('');
	const [otherFoodType, setOtherFoodType] = useState('');
	const [error, setError] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone_number: '',
		city: '',
		state: '',
		social_media_list: '',
		food_preference_list: '',
	});

	const foodTypeChange = (idx) => {
		const newFoodPreferenceList = formData.food_preference_list;
		newFoodPreferenceList[idx] = !newFoodPreferenceList[idx];
		setFormData({
			...formData,
			food_preference_list: newFoodPreferenceList,
		});
		setError({
			...error,
			food_preference_list: '',
		});
	};

	const addSocialMedia = () => {
		if (socialMediaInput !== '') {
			setFormData({
				...formData,
				social_media_list: [
					...formData.social_media_list,
					socialMediaInput,
				],
			});
			setSocialMediaInput('');
		}
	};

	const removeSocialLink = (selectLink) => {
		setFormData({
			...formData,
			social_media_list: formData.social_media_list.filter(
				(link) => link !== selectLink,
			),
		});
	};

	const submitForm = () => {
		let errorMessage = {
			first_name: '',
			last_name: '',
			email: '',
			phone_number: '',
			city: '',
			state: '',
			social_media_list: '',
			food_preference_list: '',
		};
		let errorFlag = false;
		if (formData.first_name === '') {
			errorMessage.first_name = 'First name is required';
			errorFlag = true;
		}
		if (formData.last_name === '') {
			errorMessage.last_name = 'Last name is required';
			errorFlag = true;
		}
		if (formData.email === '') {
			errorMessage.email = 'Email is required';
			errorFlag = true;
		}

		if (
			formData.phone_number === undefined ||
			formData.phone_number === ''
		) {
			errorMessage.phone_number = 'Phone number is required';
			errorFlag = true;
		}
		if (
			formData.phone_number &&
			!isPossiblePhoneNumber(formData.phone_number)
		) {
			errorFlag = true;
		}
		if (formData.city === '') {
			errorMessage.city = 'City is required';
			errorFlag = true;
		}
		if (formData.state === '') {
			errorMessage.state = 'State is required';
			errorFlag = true;
		}
		if (formData.social_media_list.length === 0) {
			errorMessage.social_media_list = 'Social media link is required';
			errorFlag = true;
		}
		if (
			formData.food_preference_list.filter(
				(foodType) => foodType === true,
			).length === 0
		) {
			errorMessage.food_preference_list = 'Food type is required';
			errorFlag = true;
		}
		setError(errorMessage);
		if (!errorFlag) {
			let selectedFoodTypes = foodTypes.filter(
				(foodType, index) =>
					formData.food_preference_list[index] === true,
			);
			if (
				formData.food_preference_list[
					formData.food_preference_list.length - 1
				]
			) {
				if (otherFoodType.length > 0) {
					selectedFoodTypes[selectedFoodTypes.length - 1] =
						otherFoodType;
				} else {
					selectedFoodTypes[selectedFoodTypes.length - 1] = 'Other';
				}
			}
			const postData = {
				...formData,
				food_preference_list: selectedFoodTypes,
			};
			console.log(postData);
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-API-KEY': 'MeetFoodMeetFoodMeetFood',
				},
				body: JSON.stringify(postData),
			};
			fetch(
				'https://9umunzzp4f.execute-api.us-east-1.amazonaws.com/prod/content-creator-landing',
				requestOptions,
			)
				.then((res) => {
					console.log(res);
					setFormEdit(false);
				})
				.catch((err) => console.log(err));
		}
	};

	useEffect(() => {
		if (!showContentCreator) {
			setFormData({
				first_name: '',
				last_name: '',
				email: '',
				phone_number: '',
				city: '',
				state: '',
				social_media_list: [],
				food_preference_list: [
					false,
					false,
					false,
					false,
					false,
					false,
				],
			});
			setError({
				first_name: '',
				last_name: '',
				email: '',
				phone_number: '',
				city: '',
				state: '',
				social_media_list: '',
				food_preference_list: '',
			});
		}
	}, [showContentCreator]);
	return (
		<div className="Modal__form">
			<h2>Become a content creator</h2>
			<div className="Modal__formRow double">
				<div>
					<div className="label">First name</div>
					<input
						className={`halfInput focusInput ${
							error.first_name.length !== 0 &&
							formData.first_name === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.first_name}
						onChange={(e) =>
							setFormData({
								...formData,
								first_name: e.target.value,
							})
						}
					/>

					<div
						className={`error ${
							error.first_name.length !== 0 &&
							formData.first_name === ''
								? `active`
								: ''
						}`}
					>
						{error.first_name}
					</div>
				</div>
				<div>
					<div className="label">Last name</div>
					<input
						className={`halfInput focusInput ${
							error.last_name.length !== 0 &&
							formData.last_name === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.last_name}
						onChange={(e) =>
							setFormData({
								...formData,
								last_name: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.last_name.length !== 0 &&
							formData.last_name === ''
								? `active`
								: ''
						}`}
					>
						{error.last_name}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">Email</div>
					<input
						className={`wholeInput focusInput ${
							(formData.email === '' && error.email.length > 0) ||
							(formData.email !== '' &&
								!isPossibleEmail(formData.email))
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.email}
						onChange={(e) =>
							setFormData({
								...formData,
								email: e.target.value,
							})
						}
					/>

					<div
						className={`error ${
							!isPossibleEmail(formData.email) ? `active` : ''
						}`}
					>
						{formData.email.length === 0
							? error.email
							: 'Must be a valid email'}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">Phone number</div>
					<PhoneInput
						formData={formData}
						setFormData={setFormData}
						error={
							(!formData.phone_number &&
								error.phone_number.length > 0) ||
							(formData.phone_number &&
								!isPossiblePhoneNumber(formData.phone_number))
						}
					/>
					<div
						className={`error ${
							!formData.phone_number ||
							(formData.phone_number &&
								!isPossiblePhoneNumber(formData.phone_number))
								? `active`
								: ''
						}`}
					>
						{!formData.phone_number
							? error.phone_number
							: 'Must be a valid phone number'}
					</div>
				</div>
			</div>
			<div className="Modal__formRow double">
				<div>
					<div className="label">City</div>
					<input
						className={`halfInput focusInput ${
							error.city.length !== 0 && formData.city === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.city}
						onChange={(e) =>
							setFormData({
								...formData,
								city: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.city.length !== 0 && formData.city === ''
								? `active`
								: ''
						}`}
					>
						{error.city}
					</div>
				</div>
				<div>
					<div className="label">State</div>
					<input
						className={`halfInput focusInput ${
							error.state.length !== 0 && formData.state === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.state}
						onChange={(e) =>
							setFormData({
								...formData,
								state: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.state.length !== 0 && formData.state === ''
								? `active`
								: ''
						}`}
					>
						{error.state}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">Social media link</div>
					<input
						className={`wholeInput focusInput ${
							error.social_media_list.length !== 0 &&
							formData.social_media_list.length === 0
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={socialMediaInput}
						onChange={(e) => setSocialMediaInput(e.target.value)}
					/>
					<br />
					<button className="buttonText" onClick={addSocialMedia}>
						+ add more
					</button>
					<div
						className={`error ${
							error.social_media_list.length !== 0 &&
							formData.social_media_list.length === 0
								? `active`
								: ''
						}`}
					>
						{error.social_media_list}
					</div>
					<div>
						{formData.social_media_list.map(
							(socialMediaLink, index) => (
								<div
									key={`socialMedia_${index}`}
									className="SocialMediaRow"
								>
									<div>{socialMediaLink}</div>
									<div>
										<img
											src={CloseImg}
											alt="close"
											onClick={() =>
												removeSocialLink(
													socialMediaLink,
												)
											}
										/>
									</div>
								</div>
							),
						)}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">
						What type of food do you prefer to film?
					</div>
					<div className="label">(Choose all that apply)</div>

					<div
						className={`error ${
							error.food_preference_list.length !== 0
								? `active`
								: ''
						}`}
						style={{ marginBottom: '16px' }}
					>
						{error.food_preference_list}
					</div>
					{foodTypes.map((foodType, index) => (
						<div className="FoodType__Row">
							<input
								className={`${
									error.food_preference_list.length !== 0
										? 'errorInput'
										: ''
								}`}
								type="checkbox"
								checked={formData.food_preference_list[index]}
								onChange={() => foodTypeChange(index)}
							/>
							<span>{foodType}</span>
						</div>
					))}
					{formData.food_preference_list[5] && (
						<div className="FoodType__Row">
							<input
								type="text"
								className="otherInput"
								value={otherFoodType}
								onChange={(e) =>
									setOtherFoodType(e.target.value)
								}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="Modal__formRow">
				<button className="Modal__submit" onClick={submitForm}>
					Submit
				</button>
			</div>
		</div>
	);
}

export default ContentCreatorForm;
