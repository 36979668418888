import React, { useContext, useState } from 'react';

const BusinessPartnerContext = React.createContext();

export function useBusinessPartner() {
	return useContext(BusinessPartnerContext);
}

export function BusinessPartnerProvider({ children }) {
	const [showBusinessPartner, setShowBusinessPartner] = useState(false);

	const value = {
		showBusinessPartner,
		setShowBusinessPartner,
	};

	return (
		<BusinessPartnerContext.Provider value={value}>
			{children}
		</BusinessPartnerContext.Provider>
	);
}
