import React from 'react';
import './Contact.scss';
import logo from '../images/logo.png';
import facebook from '../images/facebook.png';
import linkedin from '../images/linkedin.png';
import twitter from '../images/twitter.png';
import youtube from '../images/youtube.png';

function SocialMedia({ img, link }) {
	return (
		<a href={link} className="SocialMedia">
			<img src={img} alt="social" />
		</a>
	);
}

function Contact() {
	return (
		<section>
			<div className="Contact">
				<div className="Contact__info">
					<div className="Contact__logo">
						<img src={logo} alt="logo" />
					</div>
					<div>
						<p>CONTACT</p>
						<p className="Contact__email">info@meetfood.us</p>
					</div>
				</div>
				<div className="Contact__socialMedia">
					<SocialMedia img={facebook} link="/" />
					<SocialMedia img={linkedin} link="/" />
					<SocialMedia img={twitter} link="/" />
					<SocialMedia img={youtube} link="/" />
				</div>
			</div>
		</section>
	);
}

export default Contact;
