import React from 'react';
import './About.scss';
import aboutImg1 from '../images/about1.png';
import aboutImg2 from '../images/about2.png';
import { useContentCreator } from '../context/ContentCreatorContext';
import { useBusinessPartner } from '../context/BusinessPartnerContext';

function About() {
	const { setShowContentCreator } = useContentCreator();
	const { setShowBusinessPartner } = useBusinessPartner();

	return (
		<>
			<section>
				<div className="About">
					<div className="About__img">
						<img src={aboutImg1} alt="" />
					</div>
					<div className="About__text">
						<h2>Become a content creator</h2>
						<ul>
							<li>Influence with your food experience</li>
							<li>Enlighten your community</li>
							<li>Monetize through your videography passion</li>
						</ul>
						<button onClick={() => setShowContentCreator(true)}>
							Become a content creator
						</button>
					</div>
				</div>
			</section>
			<section>
				<div className="About">
					<div className="About__img">
						<img src={aboutImg2} alt="" />
					</div>
					<div className="About__text">
						<h2>Become a business partner</h2>
						<ul>
							<li>Be the advocate of your own business</li>
							<li>
								Capture unreached customers through video
								impressions
							</li>
							<li>
								Strengthen the bonding with local communities
							</li>
							<li>
								Pay only for the result with no upfront costs
							</li>
						</ul>
						<button onClick={() => setShowBusinessPartner(true)}>
							Become a business partner
						</button>
					</div>
				</div>
			</section>
		</>
	);
}

export default About;
