import React, { useState, useEffect } from 'react';
import { useBusinessPartner } from '../context/BusinessPartnerContext';
import PhoneInput from './FormComponents/PhoneInput';
import './BusinessPartnerModal.scss';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { isPossibleEmail } from './FormComponents/Validation';

const foodTypes = [
	'American',
	'Asian',
	'European',
	'Mediterranean',
	'Mexican',
	'Others (please sepcify)',
];

const businessTypes = [
	'Restaurant',
	'Mealkit',
	'Cloud kitchen',
	'Grocery',
	'Private Chef',
	'Others (please sepcify)',
];

function BusinessPartnerForm({ setFormEdit }) {
	const { showBusinessPartner } = useBusinessPartner();
	const [formData, setFormData] = useState({
		first_name: '',
		last_name: '',
		business_name: '',
		ein: '',
		street: '',
		city: '',
		state: '',
		email: '',
		phone_number: '',
		cuisine_type_list: [false, false, false, false, false, false],
		business_type: '',
	});
	const [otherFoodType, setOtherFoodType] = useState('');
	const [otherBusinessType, setOtherBusinessType] = useState('');
	const [error, setError] = useState({
		first_name: '',
		last_name: '',
		business_name: '',
		ein: '',
		street: '',
		city: '',
		state: '',
		email: '',
		phone_number: '',
		cuisine_type_list: '',
		business_type: '',
	});

	const foodTypeChange = (idx) => {
		const newFoodPreferenceList = formData.cuisine_type_list;
		newFoodPreferenceList[idx] = !newFoodPreferenceList[idx];
		setFormData({
			...formData,
			cuisine_type_list: newFoodPreferenceList,
		});
		setError({
			...error,
			cuisine_type_list: '',
		});
	};

	const businessTypeChange = (val) => {
		setFormData({
			...formData,
			business_type: val,
		});
		setError({
			...error,
			business_type: '',
		});
	};

	const submitForm = () => {
		let errorMessage = {
			first_name: '',
			last_name: '',
			business_name: '',
			ein: '',
			street: '',
			city: '',
			state: '',
			email: '',
			phone_number: '',
			cuisine_type_list: '',
			business_type: '',
		};
		let errorFlag = false;
		if (formData.first_name === '') {
			errorMessage.first_name = 'First name is required';
			errorFlag = true;
		}
		if (formData.last_name === '') {
			errorMessage.last_name = 'Last name is required';
			errorFlag = true;
		}
		if (formData.business_name === '') {
			errorMessage.business_name = 'Business name is required';
			errorFlag = true;
		}
		if (formData.street === '') {
			errorMessage.street = 'Street is required';
			errorFlag = true;
		}
		if (formData.email === '' && !isPossibleEmail(formData.email)) {
			errorMessage.email = 'Email is required';
			errorFlag = true;
		}
		if (
			formData.phone_number === undefined ||
			formData.phone_number === ''
		) {
			errorMessage.phone_number = 'Phone number is required';
			errorFlag = true;
		}
		if (
			formData.phone_number &&
			!isPossiblePhoneNumber(formData.phone_number)
		) {
			errorFlag = true;
		}
		if (formData.city === '') {
			errorMessage.city = 'City is required';
			errorFlag = true;
		}
		if (formData.state === '') {
			errorMessage.state = 'State is required';
			errorFlag = true;
		}
		if (
			formData.cuisine_type_list.filter((foodType) => foodType === true)
				.length === 0
		) {
			errorMessage.cuisine_type_list = 'Food type is required';
			errorFlag = true;
		}
		if (formData.business_type === '') {
			errorMessage.business_type = 'Business type is required';
			errorFlag = true;
		}

		setError(errorMessage);
		if (!errorFlag) {
			let selectedFoodTypes = foodTypes.filter(
				(foodType, index) => formData.cuisine_type_list[index] === true,
			);
			if (
				formData.cuisine_type_list[
					formData.cuisine_type_list.length - 1
				]
			) {
				if (otherFoodType.length > 0) {
					selectedFoodTypes[selectedFoodTypes.length - 1] =
						otherFoodType;
				} else {
					selectedFoodTypes[selectedFoodTypes.length - 1] = 'Other';
				}
			}
			let business = formData.business_type;
			if (business === 'Others (please sepcify)') {
				if (otherBusinessType.length > 0) {
					business = otherBusinessType;
				} else {
					business = 'Other';
				}
			}
			const postData = {
				...formData,
				cuisine_type_list: selectedFoodTypes,
				business_type: business,
			};
			console.log(postData);
			const requestOptions = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'X-API-KEY': 'MeetFoodMeetFoodMeetFood',
				},
				body: JSON.stringify(postData),
			};

			fetch(
				'https://9umunzzp4f.execute-api.us-east-1.amazonaws.com/prod/merchant-landing',
				requestOptions,
			)
				.then((res) => {
					console.log(res);
					setFormEdit(false);
				})
				.catch((err) => console.log(err));
		}
	};

	useEffect(() => {
		if (!showBusinessPartner) {
			setFormData({
				first_name: '',
				last_name: '',
				business_name: '',
				ein: '',
				street: '',
				city: '',
				state: '',
				email: '',
				phone_number: '',
				cuisine_type_list: [false, false, false, false, false, false],
				business_type: '',
			});
			setError({
				first_name: '',
				last_name: '',
				business_name: '',
				ein: '',
				street: '',
				city: '',
				state: '',
				email: '',
				phone_number: '',
				cuisine_type_list: '',
				business_type: '',
			});
		}
	}, [showBusinessPartner]);
	return (
		<div className="Modal__form">
			<h2>Become a business partner</h2>
			<div className="Modal__formRow double">
				<div>
					<div className="label">First name</div>
					<input
						className={`halfInput focusInput ${
							error.first_name.length !== 0 &&
							formData.first_name === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.first_name}
						onChange={(e) =>
							setFormData({
								...formData,
								first_name: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.first_name.length !== 0 &&
							formData.first_name === ''
								? `active`
								: ''
						}`}
					>
						{error.first_name}
					</div>
				</div>
				<div>
					<div className="label">Last name</div>
					<input
						className={`halfInput focusInput ${
							error.last_name.length !== 0 &&
							formData.last_name === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.last_name}
						onChange={(e) =>
							setFormData({
								...formData,
								last_name: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.last_name.length !== 0 &&
							formData.last_name === ''
								? `active`
								: ''
						}`}
					>
						{error.last_name}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">Business name</div>
					<input
						className={`halfInput focusInput ${
							error.business_name.length !== 0 &&
							formData.business_name === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.business_name}
						onChange={(e) =>
							setFormData({
								...formData,
								business_name: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.business_name.length !== 0 &&
							formData.business_name === ''
								? `active`
								: ''
						}`}
					>
						{error.business_name}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">
						Employer identification number (optional)
					</div>
					<input
						className="wholeInput focusInput"
						type="text"
						value={formData.ein}
						onChange={(e) =>
							setFormData({
								...formData,
								ein: e.target.value,
							})
						}
					/>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">Street</div>
					<input
						className={`wholeInput focusInput ${
							error.street.length !== 0 && formData.street === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.street}
						onChange={(e) =>
							setFormData({
								...formData,
								street: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.street.length !== 0 && formData.street === ''
								? `active`
								: ''
						}`}
					>
						{error.street}
					</div>
				</div>
			</div>
			<div className="Modal__formRow double">
				<div>
					<div className="label">City</div>
					<input
						className={`halfInput focusInput ${
							error.city.length !== 0 && formData.city === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.city}
						onChange={(e) =>
							setFormData({
								...formData,
								city: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.city.length !== 0 && formData.city === ''
								? `active`
								: ''
						}`}
					>
						{error.city}
					</div>
				</div>
				<div>
					<div className="label">State</div>
					<input
						className={`halfInput focusInput ${
							error.state.length !== 0 && formData.state === ''
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.state}
						onChange={(e) =>
							setFormData({
								...formData,
								state: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							error.state.length !== 0 && formData.state === ''
								? `active`
								: ''
						}`}
					>
						{error.state}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">Email</div>
					<input
						className={`wholeInput focusInput ${
							(formData.email === '' && error.email.length > 0) ||
							(formData.email !== '' &&
								!isPossibleEmail(formData.email))
								? 'errorInput'
								: ''
						}`}
						type="text"
						value={formData.email}
						onChange={(e) =>
							setFormData({
								...formData,
								email: e.target.value,
							})
						}
					/>
					<div
						className={`error ${
							!isPossibleEmail(formData.email) ? `active` : ''
						}`}
					>
						{formData.email.length === 0
							? error.email
							: 'Must be a valid email'}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">Phone number</div>
					<PhoneInput
						formData={formData}
						setFormData={setFormData}
						error={
							(!formData.phone_number &&
								error.phone_number.length > 0) ||
							(formData.phone_number &&
								!isPossiblePhoneNumber(formData.phone_number))
						}
					/>
					<div
						className={`error ${
							!formData.phone_number ||
							(formData.phone_number &&
								!isPossiblePhoneNumber(formData.phone_number))
								? `active`
								: ''
						}`}
					>
						{!formData.phone_number
							? error.phone_number
							: 'Must be a valid phone number'}
					</div>
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">
						Please choose your cuisine type?
					</div>
					<div className="label">(Choose all that apply)</div>
					<div
						className={`error ${
							error.cuisine_type_list.length !== 0 ? `active` : ''
						}`}
						style={{ marginBottom: '16px' }}
					>
						{error.cuisine_type_list}
					</div>
					{foodTypes.map((foodType, index) => (
						<div className="FoodType__Row">
							<input
								className={`${
									error.cuisine_type_list.length !== 0
										? 'errorInput'
										: ''
								}`}
								type="checkbox"
								checked={formData.cuisine_type_list[index]}
								onChange={() => foodTypeChange(index)}
							/>
							<span>{foodType}</span>
						</div>
					))}
					{formData.cuisine_type_list[5] && (
						<div className="FoodType__Row">
							<input
								type="text"
								className="otherInput"
								value={otherFoodType}
								onChange={(e) =>
									setOtherFoodType(e.target.value)
								}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="Modal__formRow">
				<div>
					<div className="label">
						Please choose your business type?
					</div>
					<div
						className={`error ${
							error.business_type.length !== 0 ? `active` : ''
						}`}
						style={{ marginBottom: '16px' }}
					>
						{error.business_type}
					</div>
					{businessTypes.map((businessType, index) => (
						<div
							key={`BusinessType_${index}`}
							className="FoodType__Row"
						>
							<input
								type="radio"
								name="business"
								className={`${
									error.business_type.length !== 0
										? 'errorInput'
										: ''
								}`}
								value={businessType}
								checked={
									formData.business_type === businessType
								}
								onChange={(e) =>
									businessTypeChange(e.target.value)
								}
							/>
							<span>{businessType}</span>
						</div>
					))}
					{formData.business_type === 'Others (please sepcify)' && (
						<div className="FoodType__Row">
							<input
								type="text"
								className="otherInput"
								value={otherBusinessType}
								onChange={(e) =>
									setOtherBusinessType(e.target.value)
								}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="Modal__formRow">
				<button className="Modal__submit" onClick={submitForm}>
					Submit
				</button>
			</div>
		</div>
	);
}

export default BusinessPartnerForm;
