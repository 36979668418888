import React, { useContext, useState } from 'react';

const ContentCreatorContext = React.createContext();

export function useContentCreator() {
	return useContext(ContentCreatorContext);
}

export function ContentCreatorProvider({ children }) {
	const [showContentCreator, setShowContentCreator] = useState(false);

	const value = {
		showContentCreator,
		setShowContentCreator,
	};

	return (
		<ContentCreatorContext.Provider value={value}>
			{children}
		</ContentCreatorContext.Provider>
	);
}
