import React, { useState } from 'react';
import Input from 'react-phone-number-input/input';

function PhoneInput({ formData, setFormData, error }) {
	const [phone, setPhone] = useState('');

	const changePhone = (val) => {
		setPhone(val);
		setFormData({
			...formData,
			phone_number: val,
		});
	};

	return (
		<div className={`PhoneInput ${error ? 'errorPhoneInput' : ''}`}>
			<Input country="US" value={phone} onChange={changePhone} />
		</div>
	);
}

export default PhoneInput;
