import React from 'react';
import './Partners.scss';
import partner1Img from '../images/partner/partner1.png';
import partner2Img from '../images/partner/partner2.png';
import partner3Img from '../images/partner/partner3.png';
import partner4Img from '../images/partner/partner4.png';
import partner5Img from '../images/partner/partner5.png';
import partner7Img from '../images/partner/partner7.png';

function PartnerBox({ img }) {
	return (
		<div>
			<img src={img} alt="partner" />
		</div>
	);
}

function Partners() {
	return (
		<section>
			<div className="Partners">
				<h1>Our pilot business partners</h1>
				<div className="PartnerContainer">
					<PartnerBox img={partner1Img} />
					<PartnerBox img={partner2Img} />
					<PartnerBox img={partner3Img} />
					<PartnerBox img={partner5Img} />
					<PartnerBox img={partner7Img} />
					<PartnerBox img={partner4Img} />
				</div>
			</div>
		</section>
	);
}

export default Partners;
