import React from 'react';
import Navbar from './Navbar';
import './Header.scss';
import mainImg from '../images/main2.png';
import appleImg from '../images/apple.png';
import googleImg from '../images/googlePlay.png';

function Header() {
	return (
		<div id="main">
			<Navbar />
			<div className="name">
				<div className="main__text">
					<h6>Coming soon</h6>
					<h1>Discover your next dish on meetfood</h1>
					<p className="details">
						We build the future of social commerce for the food
						industry
					</p>
					<div className="store-btns">
						<a href="https://apps.apple.com/us/app/meetfood-explore-reviews/id1603020687" className="store-btn live">
							<div className="store-btn-content">
								<div className="store-img">
									<img src={appleImg} alt="apple" />
								</div>
								<div>
									<div className="action-btn">
										Download on the
									</div>
									<div>App Store</div>
								</div>
							</div>
						</a>
						<a href="/" className="store-btn">
							<div className="store-btn-content">
								<div className="store-img">
									<img src={googleImg} alt="google play" />
								</div>
								<div>
									<div className="action-btn">GET IT ON</div>
									<div>Google Play</div>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div className="main__img">
					<img src={mainImg} alt="main" />
				</div>
			</div>
		</div>
	);
}

export default Header;
