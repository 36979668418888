import React, { useState } from 'react';
import logo from '../images/logo2.png';
import './Navbar.scss';
import { useContentCreator } from '../context/ContentCreatorContext';
import { useBusinessPartner } from '../context/BusinessPartnerContext';

function Navbar() {
	const { setShowContentCreator } = useContentCreator();
	const { setShowBusinessPartner } = useBusinessPartner();
	const [nav, setNav] = useState(false);

	const changeBackground = () => {
		if (window.scrollY >= 50) {
			setNav(true);
		} else {
			setNav(false);
		}
	};

	window.addEventListener('scroll', changeBackground);

	return (
		<nav className={nav ? 'nav active' : 'nav'}>
			<div>
				<a href="/" className="logo">
					<img src={logo} alt="" />
				</a>
				<input type="checkbox" className="menu-btn" id="menu-btn" />
				<label className="menu-icon" htmlFor="menu-btn">
					<span className="nav-icon"></span>
				</label>
				<ul className="menu">
					<li>
						<a className="general" href="/">
							Home
						</a>
					</li>
					<li
						className="model__link"
						onClick={() => setShowBusinessPartner(true)}
					>
						Become business partner
					</li>
					<li
						className="model__link"
						onClick={() => setShowContentCreator(true)}
					>
						Become content creator
					</li>
					<li>
						<a className="general" href="mailto:info@meetfood.us">
							Contact us
						</a>
					</li>
					<li>
						<a className="special" href="/">
							Merchant login
						</a>
					</li>
				</ul>
			</div>
		</nav>
	);
}

export default Navbar;
